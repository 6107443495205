<template>
	<div>
		<main-header></main-header>
		<router-view></router-view>
		<main-footer></main-footer>
	</div>
</template>

<script>
	import Components from '@/components/layouts'
	export default {
		components: {
			...Components
		}
	}
</script>

<style>
</style>